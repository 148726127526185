<template>
  <div class="test">
    <div class="test__wrapper">
      <Section limiter>
        <div class="grid">
          <div class="grid__wrapper">
            <GridItem v-for="item in $places.data"
                      :key="item.id"
                      :params="item"/>
          </div>
        </div>
      </Section>
    </div>
  </div>
</template>

<script>
import GridItem from '../components/GridItem/GridItem.vue';

export default {
  name: 'TestView',
  components: { GridItem },
  created() {
    this.$store.dispatch('GET_PLACES_FROM_SERVER_WITH_PARAMS', {
      clear: true,
      params: {
        count: 100500,
        city_id: this.$city.id,
        lang: this.$i18n.locale,
      },
    });
  },
};
</script>

<style lang="scss">
  @import "./src/assets/style/utilits/index";

  .test {
    &__wrapper {
      padding: 160px 0 80px;
    }
  }

  .grid {
    &__wrapper {
      display: grid;
      grid-gap: 30px;
      grid-auto-flow: dense;

      @media (min-width: 1110px) {
        grid-template-columns: repeat(3, 1fr);
      }

      @media (min-width: 768px) and (max-width: 1109px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media (min-width: 1px) and (max-width: 767px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
</style>
