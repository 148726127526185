<template>
  <router-link :to="`${$locale.base}/${entity}/${id}`"
    class="grid__item"
    v-on:mouseenter.native="showInfo"
    v-on:mouseleave.native="hideInfo"
    :class="classList">
    <div class="grid__item-image-holder">
      <img class="grid__item-image"
        :src="image"/>
    </div>
    <transition name="fade"
      mode="in-out">
      <Column v-if="!show"
        class="grid__item-info-holder"
        justify="end">
        <h5>{{title}}</h5>
      </Column>
      <Column v-if="show && !youtube"
        class="grid__item-info-holder"
        justify="end">
        <h5>{{title}}</h5>
        <p v-if="!!annotation">{{ annotation }}</p>
      </Column>
      <Column class="grid__item-video-holder"
        v-if="show && !!youtube">
        <Row justify="between"
          style="height: 100%">
          <Column class="grid-lg-8"
            style="height: 100%">
            <VideoYouTube :params="{width: '100%', height: '100%', src: youtube}"/>
          </Column>
          <Column class="grid__item-video-info grid-lg-4">
            <h5>{{title}}</h5>
            <p v-if="!!annotation">{{ annotation }}</p>
          </Column>
        </Row>
      </Column>
    </transition>
    <Favorite :params="{id, entity, favorited}"/>
    <Rating :params="{id, type: entity, rating, currentUserRating, component: 'Column'}"/>
  </router-link>
</template>

<script>
import errorImage from '@/assets/images/svg/error.svg';

export default {
  name: 'GridItem',
  data() {
    return {
      show: false,
    };
  },
  props: {
    params: {},
  },
  computed: {
    id() {
      return this?.params?.id;
    },
    entity() {
      return this?.params?.entity;
    },
    favorited() {
      return this?.params?.favorited;
    },
    title() {
      return this?.params?.title;
    },
    annotation() {
      return this?.params?.annotation.replace(/&lt;[^>]+>/g, '');
    },
    rating() {
      return this?.params?.rating;
    },
    image() {
      if (this?.params?.images?.length > 0) return this?.params?.images[0];
      return errorImage;
    },
    currentUserRating() {
      return this?.params?.currentUserRating;
    },
    youtube() {
      return this?.params?.youtube;
    },
    // eslint-disable-next-line consistent-return,vue/return-in-computed-property
    classList() {
      if (this.rating < 4 && !this.youtube) {
        return 'grid__item-size-1';
      }
      if (this.rating === 4 && !this.youtube) {
        if (this.$viewport.mobile) return 'grid__item-size-1';
        if (this.$viewport.tablet) return 'grid__item-size-1';
        return 'grid__item-size-2';
      }
      if (this.rating === 5 || !!this.youtube) {
        if (this.$viewport.mobile) return 'grid__item-size-1';
        if (this.$viewport.tablet) return 'grid__item-size-2';
        return 'grid__item-size-3';
      }
    },
  },
  methods: {
    showInfo() {
      this.show = true;
    },
    hideInfo() {
      this.show = false;
    },
  },
};
</script>

<style lang="scss">
  @import "./src/assets/style/utilits/index";

  .grid {
    &__item {
      position: relative;
      height: 405px;
      background: var(--white);
      border-radius: 5px;

      &-image {
        @include size(100%, 100%);

        &-holder {
          @include position(absolute, $top: 0, $left: 0);
          @include size(100%, 100%);
          border-radius: 5px;
          overflow: hidden;
        }
      }

      &-info-holder {
        @include position(absolute, $top: 0, $left: 0);
        @include size(100%, 100%);
        padding: 20px 70px 20px 20px;
        border-radius: 5px;

        h5 {
          @include font(18px, bold, 1.5);
          color: var(--white);
          margin-bottom: 12px;
          text-shadow: 0 0 3px var(--black);

          &:only-child {
            margin-bottom: 0;
          }
        }

        p {
          display: none;
          @include font(12px, normal, 1.5);
          color: var(--white);
        }

        &:hover {
          background: rgba(0, 0, 0, .8);
          transition: background .5s ease;

          h5 {
            @include font(16px, bold, 1.5);
          }

          p {
            display: block;
          }
        }
      }

      &-video {
        &-holder {
          @include position(absolute, $top: 0, $left: 0);
          @include size(100%, 100%);
          background: rgba(0, 0, 0, .8);
          overflow: hidden;
          border-radius: 5px;
          transition: background .5s ease;

          .video-you-tube {
            @include size(100%, 100%);

            &__wrapper {
              @include size(100%, 100%);
            }
          }
        }

        &-info {
          padding: 20px 70px 20px 0;

          h5 {
            @include font(14px, bold, 1.5);
            color: var(--white);
            margin-bottom: 12px;

            &:only-child {
              margin-bottom: 0;
            }
          }

          p {
            @include font(12px, normal, 1.5);
            color: var(--white);
          }
        }
      }

      .favorite {
        @include position(absolute, $top: 20px, $right: 16px);
      }

      .rating {
        @include position(absolute, $top: 70px, $right: 20px);

        &__icon {
          margin: 0 0 8px;
        }

        &__number {
          color: var(--white);
          text-shadow: 0 0 3px var(--black);
        }
      }

      &-size {
        &-1 {
          grid-column-end: span 1;
        }

        &-2 {
          grid-column-end: span 2;
        }

        &-3 {
          grid-column-end: span 3;
        }
      }
    }
  }
</style>
